<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center mb-4">
        <div class="col-lg-3 col-5 align-self-center">
          <img src="../../../assets/Dtech_cores.png" alt="Logo">
        </div>
      </CRow>
      <CRow class="justify-content-center">
        <CCol sm="12" xl="5" lg="5" md="5">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="handleSubmit">
                  <h1>{{ ('Login') }}</h1>
                  <p class="text-muted">Sign In to your account</p>
                    <!-- Email -->
                  <CInput :placeholder="('Email')" autocomplete="email email" v-model="email" :invalid-feedback="('Invalid Email')" :is-valid="validator">
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <!-- Password -->
                  <CInput v-if="!isRecoveringPassword" :placeholder="('Password')" type="password" autocomplete="curent-password" v-model="password" :invalid-feedback="('Invalid Password')" :is-valid="validator">
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                    <!-- Description of forgot password -->
                  <p v-else>{{("An email with a link to define a password will be sent to your email.")}}</p>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <!-- Forgot password -->
                      <CButton v-if="!isRecoveringPassword" color="link" class="px-0" @click="isRecoveringPassword = true">{{ ('Forgot password?') }}</CButton>
                      <CButton v-else color="link" class="px-0" @click="isRecoveringPassword = false">{{ ('Go back') }}</CButton>
                    </CCol>
                    <!-- Confirm -->
                    <CCol col="6" class="text-right">
                      <CSpinner v-if="loading" variant="success" small></CSpinner>
                      <div v-else>
                        <CButton v-if="!isRecoveringPassword" color="primary" class="px-4" @click="loginClicked">{{ ('Login') }}</CButton>
                        <CButton v-else color="primary" class="px-4" @click="sendEmailClicked">{{ ('Send email') }}</CButton>
                      </div>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      email: '',
      password: '',
      loginError: false,
      submitted: false,
      loading: false,
      isRecoveringPassword: this.$route.params.isRecoveringPassword
    }
  },
  computed:
  {
    ...mapState({ 
        alertType: state => state.alert.type,
        alertOutput: state => state.alert.output
    }), 
    ...mapState('account', ['requestLink']),
  },
  name: 'Login',
  methods: {
    ...mapActions('account', ['login']),
    ...mapActions('account', ['requestLinkPassword']), 
     ...mapActions({
          clearAlert: 'alert/clear' 
      }),
    loginClicked: function(){
      this.submitted = true;


      const { email, password } = this;
      if (email && password) {
        this.loading = true;
        this.login({ email, password })
      }
    },
    validator(val) 
    {
      var result = undefined; // This is the default, in case the user hasn't submitted yet

      if(this.submitted && (!val || this.loginError))
      {
        result = false;
      }

      return result;
    },
    sendEmailClicked: function()
    {
      if(this.email)
      {
        this.loading = true;
        this.requestLinkPassword(this.email);
      }
    }
  },
  created: function()
  {
    this.clearAlert();
  },
  watch: 
  { 
    alertType: function()
    {
      this.loginError = this.alertType === "alert-danger";
    },
    alertOutput: function()
    {
      this.loginError = this.alertType === "alert-danger";
    }, 
    email: function()
    {
      this.loginError = false;
    },
    password: function()
    {
      this.loginError = false;
    },
    loginError: function(val)
    {
      if(val)
      {
        this.loading = false;
      }
    },
  },
  
     requestLink: function(val)
    {
      if(val !== undefined)
      {

        var result = ("Recover Password Error");

        if(val == true)
        {
          result = ("Recover Password Success");
          this.isRecoveringPassword = false;
        }

        this.loading = false;
        alert(result);
      }
    } 
   
}
</script>
